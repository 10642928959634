code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  font-size: 62.5%;
  overflow-x: hidden;

  /* Remove margins and padding around content */
  margin: 0;
  padding: 0;

  /* Use repeating image background */
  /* background-image: url("RepeatPattern.png"); */

  /* Fix background during scrolling */
  background-attachment: fixed;
  background-color: #ffffff;
  /* Create a gradient background */
  background-image: -webkit-linear-gradient(top, #000, gray);
  background-image: -moz-linear-gradient(top, #000, gray);
  background-image: -ms-linear-gradient(top, #000, gray);
  background-image: -o-linear-gradient(top, #000, gray);
  background-image: linear-gradient(top, #000, gray);

  /* Use scalable background image */
  /* background-image: url("mountain-bkgrd.png"); */
  background-position: center center;
  background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  font-family: Avenir-Black, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Remove default margin and padding from every element */
* {
  margin: 0;
  padding: 0;
}

/* Includes padding, margins with width and height of elements */
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.wrapper {
  font-size: 1.6em;
  padding: 2em;
  margin: 0 auto;
  width: 900px;
  height: 2000px;
  background-color: #fff;

  /* Place nonrepeating background */
  /* background-image: url("flower-bkgrd.png"); */
  background-repeat: no-repeat;
  background-position: bottom right;

  /* Multiple background images */
  /* background: url("flower-bkgrd.png") top right no-repeat,
  url("flower-bkgrd.png") bottom right no-repeat, #FFF; */

  /* Transparent background */
  opacity: 0.9;
}

/* Use custom fonts : https://www.google.com/fonts#
Font Squirrel is often used */
h1 {
  font-family: "Lobster", cursive;
}

/* Eliminate link underlines */

a:link,
a:visited {
  text-decoration: none;
}

/* Highlight links on hover */
a:hover {
  background-color: #faebd7;
}
