:root {
  font-size: 62.5%;
  body {
    margin: 0;
    // Body font size 16px
    font-size: 1.6rem;
    font-family: 'Avenir Next LT Pro';
  }
}
.action-button {
  cursor: pointer;
}
.success-icon {
  .MuiSvgIcon-root {
    font-size: 28px;
    color: #4caf50;
  }
}
.error-icon {
  .MuiSvgIcon-root {
    font-size: 28px;
    color: #f44336;
  }
}
.popup-text {
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #1d252d;
}
.email-verify {
  padding: 38px 50px;
  max-width: 700px;
  margin: 0 auto;
  line-height: 1.6;
  color: #1d252d;
  margin-top: 40px;
  border-radius: 4px;
  min-height: 260px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  .logo {
    text-align: center;
    margin-bottom: 24px;
    img {
      width: 40%;
    }
  }
  .title {
    font-weight: 700;
    font-size: 16px;
    position: relative;
    top: 0px;
  }
  p {
    font-size: 15px;
    line-height: 1.5;
  }
}
.link-title img {
  width: 26px;
  position: relative;
  top: 1px;
  padding-right: 6px;
}
button {
  cursor: pointer;
}
.link-title {
  font-size: 1.5rem;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #fff;
  text-decoration: none;
}

.MuiMenu-paper {
  margin-top: 10px;
}

.top-menu {
  width: 401px;
  padding: 5px 13px;
  ul li {
    width: 113px;
    color: #1d252d;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    margin: 3px !important;
    padding: 6px 10px;
    display: inline-block;
    font-weight: 500;
  }
  li img {
    width: 18px;
    display: inline-block;
    position: relative;
    top: 5px;
    right: 2px;
  }
  .menu-auth .menu li span {
    display: inline-block;
  }
}

.comming-soon {
  opacity: 0.8;
  color: #e4e4e4;
  font-size: 13rem;
  font-weight: 700;
}
.home-container {
  height: 100%;
  position: relative;
  width: 100%;
  .common-container {
    padding: 20px 24px 70px 24px;
    max-width: 1230px;
    margin: 0 auto !important;
  }
}
.inner-service {
  position: relative;
  top: 5px;
  .service-text {
    font-size: 12px;
  }
  .service-grid {
    margin: 6px;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid #e4e4e4;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 122px;
    min-height: 122px;
    justify-content: center;
    align-items: center;
  }
}

.wrapper-grid {
  min-height: 620px;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-image: url(../assets/images/bg.png);
  display: flex;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.menu-auth {
  margin-top: 14px;
  .MuiTooltip-tooltip {
    background: #fff;
    padding: 15px 8px 12px;
    max-width: 400px;
    margin-top: 20px;
  }
  .menu {
    width: 420px;
    padding: 5px;
  }
  .MuiTooltip-arrow {
    color: #fff !important;
  }
  .menu li:hover {
    border-radius: 4px;
    background-color: #e4e4e4;
  }
  .menu li {
    width: 118px;
    color: #1d252d;
    font-size: 13px;
    line-height: 24px;
    cursor: pointer;
    margin: 3px !important;
    padding: 5px 8px;
    display: inline-block;
    font-weight: 500;
    img {
      width: 18px;
      display: inline-block;
      position: relative;
      top: 5px;
      right: 2px;
    }
    span {
      display: inline-block;
    }
  }

  .link-title {
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    text-decoration: none;
  }
  .down-menu-icon {
    position: relative;
    top: 5px;
  }
}
.email-grid {
  .common-form {
    width: 531px;
    min-width: 360px;
    input {
      width: 70%;
      height: 62px;
      float: left;
    }
    input:focus {
      outline: none;
    }
    button {
      width: 29%;
      height: 62px;
      cursor: pointer;
      float: left;
    }
  }
}
.footer {
  padding: 50px 0px;
  background-color: #1d252d;
  .footer-container {
    padding-left: 24px;
    padding-right: 24px;
    max-width: 1230px;
  }
  .footer-text {
    font-size: 26px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .common-form {
    width: 52%;
    margin: 0 auto;
    min-width: 360px;
    input {
      width: 70%;
      height: 62px;
      float: left;
    }
    input:focus {
      outline: none;
    }
    button {
      width: 29%;
      height: 62px;
      float: left;
    }
  }
  .formInput {
    padding: 15px;
    border: solid 1.5px #e4e4e4;
    border-radius: 5px 0px 0px 5px;
    border-right: 0;
    width: 80%;
  }
  .what-app-section {
    padding-top: 20px;
    text-align: center;
    img {
      display: inline-block;
      position: relative;
      right: 10px;
      top: 3px;
    }
    p {
      display: inline-block;
      font-family: Avenir;
      font-size: 40px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      letter-spacing: normal;
      text-align: left;
      color: #40c351;
    }
  }
}

// Menu css
.list-drop-down {
  .dropdown__title {
    background-color: transparent;
    border: none;
    font-family: inherit;
  }

  nav > ul {
    grid-auto-flow: column;
  }
  nav > ul > li {
    margin: 0 0.5rem;
  }
  nav > ul > li a,
  nav > ul > li .dropdown__title {
    text-decoration: none;
    text-align: center;
    display: inline-block;
    color: #fff;
  }
  nav > ul > li a:focus,
  nav > ul > li .dropdown__title:focus {
    outline: none;
  }
  nav > ul > li > a,
  nav > ul > li .dropdown__title {
    padding: 1rem 0.5rem;
    border-top: 3px solid transparent;
    -webkit-transition: 280ms all 120ms ease-out;
    transition: 280ms all 120ms ease-out;
  }
  nav > ul > li > a:hover,
  nav > ul > li > a:focus,
  nav > ul > li .dropdown__title:hover,
  nav > ul > li .dropdown__title:focus {
    color: #ffb63e;
  }
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .dropdown {
    position: relative;
    top: 5px;
  }
  .dropdown .dropdown__title {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
  }
  .dropdown .dropdown__title:after {
    content: '';
    border: 0.35rem solid transparent;
    border-top-color: #fff;
    margin-left: 0.25em;
    -webkit-transform: translateY(0.15em);
    transform: translateY(0.15em);
    -o-transform: translateY(0.15em);
    -ms-transform: translateY(0.15em);
  }
  .dropdown__title:hover:after {
    border-top-color: #ffb63e;
    transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    top: -5px;
    position: relative;
  }
  .dropdown .dropdown__menu {
    position: absolute;
    min-width: 15ch;
    left: 50%;
    top: calc(100% - 0.25rem);
    -webkit-transition: 280ms all 120ms ease-out;
    transition: 280ms all 120ms ease-out;

    -webkit-transform: rotateX(-90deg) translateX(-60%);
    transform: rotateX(-90deg) translateX(-60%);
    -o-transform: rotateX(-90deg) translateX(-60%);
    -ms-transform: rotateX(-90deg) translateX(-60%);

    -webkit-transform-origin: top center;
    transform-origin: top center;
    visibility: hidden;
    opacity: 0.3;
    padding: 0.5em 0;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0.15em 0.25em rgba(0, 0, 0, 0.25);
  }
  .dropdown .dropdown__menu a {
    color: #47246b;
    display: block;
    padding: 0.5em;
    opacity: 0;
    -webkit-transition: 280ms all 120ms ease-out;
    transition: 280ms all 120ms ease-out;
  }
  .dropdown .dropdown__menu a:hover {
    background-color: rgba(102, 51, 153, 0.15);
  }
  .dropdown .dropdown__menu a:focus {
    outline: none;
    background-color: rgba(102, 51, 153, 0.25);
  }
  .dropdown:after {
    content: '';
    border: 0.5rem solid transparent;
    border-bottom-color: #fff;
    position: absolute;
    top: calc(100% - 1.25rem);
    left: 50%;
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -o-transform: translateX(-50%);

    -webkit-transition: 280ms all 120ms ease-out;
    transition: 280ms all 120ms ease-out;
    opacity: 0;
    will-change: opacity;
  }

  .dropdown:hover .dropdown__menu,
  .dropdown:focus-within .dropdown__menu {
    opacity: 1;
    -ms-transform: rotateX(0) translateX(-60%);
    -webkit-transform: rotateX(0) translateX(-60%);
    transform: rotateX(0) translateX(-60%);
    -o-transform: rotateX(0) translateX(-60%);
    visibility: visible;
  }
  .dropdown:hover .dropdown__menu a,
  .dropdown:focus-within .dropdown__menu a {
    opacity: 1;
  }
  .dropdown:hover:after,
  .dropdown:focus-within:after {
    opacity: 1;
  }
}

//

@media (min-width: 360px) and (max-width: 600px) {
  .wrapper-grid {
    background-position: 64% 100% !important;
  }
  .common-container {
    padding: 14px 14px 60px 14px !important;
  }
  .list-drop-down .dropdown {
    top: 0px;
  }
  .dropdown {
    .link-title img {
      width: 21px;
      position: relative;
      padding-right: 4px;
    }
    .link-title {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
  .comming-soon {
    font-size: 30px;
    margin-top: 7px;
  }
  .common-form {
    button {
      padding: 0px;
    }
    input {
      border: solid 1px #e4e4e4;
    }
    input,
    button {
      height: 50px !important;
      float: left;
    }
  }
  .email-grid .common-form {
    width: 100%;
    min-width: 345px;
  }
  .inner-service {
    top: 30px;
    left: 2px;
    .service-text {
      font-size: 10px;
    }
    .service-grid {
      margin: -1px;
      box-shadow: none;
      width: 25%;
      min-height: 81px;
      border-radius: 0;
    }
  }
  .footer .common-form input {
    width: 69%;
    height: 50px;
  }
  .footer {
    .footer-text {
      font-size: 15px;
    }
    .common-form button {
      width: 30%;
      height: 50px;
    }
    .common-form {
      width: 100%;
      margin: 0 auto;
      min-width: 100%;
    }
    .what-app-section {
      p {
        font-size: 26px;
      }
      img {
        width: 23px;
        position: relative;
        top: 4px;
        right: 9px;
      }
    }
  }
  // Menu
  .dropdown:hover .dropdown__menu,
  .dropdown:focus-within .dropdown__menu {
    -webkit-transform: rotateX(0) translateX(-78%) !important;
    transform: rotateX(0) translateX(-78%) !important;
    -ms-transform: rotateX(0) translateX(-78%) !important;
    -o-transform: rotateX(0) translateX(-78%) !important;
  }
  .dropdown .dropdown__menu {
    -webkit-transform: rotateX(-90deg) translateX(-78%) !important;
    transform: rotateX(-90deg) translateX(-78%) !important;
    -ms-transform: rotateX(-90deg) translateX(-78%) !important;
    -o-transform: rotateX(-90deg) translateX(-78%) !important;
  }
  .top-menu {
    width: 301px;
    padding: 5px 13px;
  }
  .top-menu ul li {
    padding: 6px 10px;
    min-height: 30px;
    margin: 2px 2px 2px 16px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .comming-soon {
    font-size: 5rem;
  }
  .inner-service {
    margin: 36px 30px 0px 0px;
  }
  .link-title {
    font-size: 1.5rem;
  }

  .email-grid .common-form {
    width: 78% !important;
  }
  .wrapper-grid {
    background-position: 69% 100%;
  }
}

@media (min-width: 960px) and (max-width: 1050px) {
  .inner-service .service-grid {
    width: 130px;
  }
}
