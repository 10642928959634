@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-MediumCn.woff2") format("woff2"),
    url("AvenirNextLTPro-MediumCn.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-Regular.woff2") format("woff2"),
    url("AvenirNextLTPro-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next LT Pro";
  src: url("AvenirNextLTPro-Bold.woff2") format("woff2"), url("AvenirNextLTPro-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
